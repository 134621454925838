<template>

    <aside id="s-main-menu" class="sidebar">
        
        <div class="smm-header">
            <i class="zmdi zmdi-long-arrow-left" data-ma-action="sidebar-close"></i>
        </div>

        <ul class="smm-alerts">
            <!-- <li @click="profile">
                <i class="zmdi zmdi-account"></i>
            </li> -->
            <li @click="settings">
                <i class="zmdi zmdi-settings"></i>
            </li>
            <li @click="logout">
                <i class="zmdi zmdi-power"></i>
            </li>
        </ul>

        <ul class="main-menu">
            <li>
                <router-link to="/"><i class="zmdi zmdi-home"></i>Dashboard</router-link>
            </li>
            <li>
                <router-link to="/staff"><i class="zmdi zmdi-account"></i>Staff</router-link>
            </li>
            <li v-if="$store.state.user.role == 'superadmin'">
                <router-link to="/users"><i class="zmdi zmdi-accounts"></i>Users</router-link>
            </li>
            <li>
                <router-link to="/settings"><i class="zmdi zmdi-settings"></i>Settings</router-link>
            </li>
            <li>
                <a @click="logout" style="cursor: pointer;"><i class="zmdi zmdi-power"></i> Logout</a>
            </li>
        </ul>
        
    </aside>

</template>

<script>
export default {
    data(){
        return {}
    },
    methods: {
        logout() {
            this.$swal
                .fire({
                    type: "warning",
                    title: "LOGOUT",
                    text: 'Are you sure you want to logout?',
                    showCancelButton: true,
                    focusConfirm: true,
                })
                .then(response => {
                    if (response.value) {
                        this.$store.dispatch('logout')
                        this.$router.push('/login')
                    }
                })
        },
        profile() {
            this.$router.push('/profile')
        },
        settings() {
            this.$router.push('/settings')
        },

    },
}
</script>